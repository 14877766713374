<template>
  <div>
    <v-expansion-panels mb="3">
      <v-expansion-panel v-for="reference in this.referenceList" :key="reference.id">
        <v-expansion-panel-header :color="getColor(reference)">
          <v-row>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">ФИО</p>
                {{ reference.fullName }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Почта</p>
                {{ reference.mail }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Телефон</p>
                {{ reference.phone }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Вид справки</p>
                {{ getReferenceTypeName(reference.referenceType) }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Заказано</p>
                {{ formatDate(reference.createdAt) }}
              </div>
            </v-col>
            <v-col>
              <div>
                <p class="ReferenceOperatorListTableHeader">Статус</p>
                {{ getStatus(reference) }}
              </div>
              <div v-if="isOrderTaken(reference) !== false">
                <p style="color: #e21a1a" class="pt-3">Забронирована оператором: {{ isOrderTaken(reference) }}</p>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content :color="getColor(reference)">
          <div>Заказ № {{ reference.orderNumber }}</div>
          <br />
          <v-card-actions>
            <ReferenceOrderItem :order="reference" :queryParams="queryParams" />
            <ReferenceActionItem :order="reference" :queryParams="queryParams" />
          </v-card-actions>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import ViewHelper from '@/helpers/view.helper';
import ReferenceOrderItem from '@/components/reference/ReferenceOrderItem';
import ReferenceActionItem from '@/components/reference/ReferenceActionItem';

import moment from 'moment';
export default {
  props: {
    referenceList: {
      type: Array,
      required: true
    },
    queryParams: {
      required: true
    }
  },
  components: {
    ReferenceOrderItem,
    ReferenceActionItem
  },
  computed: {
    ...mapGetters('reference', ['orders'])
  },
  methods: {
    getSeatName: ReferenceHelper.getSeatName,
    getCarName: ReferenceHelper.getCarName,
    getTariffName: ReferenceHelper.getTariffName,
    getStationName: ReferenceHelper.getStationName,
    getOrderStatus: ReferenceHelper.getOrderStatus,
    formatDate: ViewHelper.formatDate,
    formatDateOnly: ViewHelper.formatDateOnly,
    getReferenceTypeName: ReferenceHelper.getReferenceTypeName,
    getStatus(reference) {
      return ReferenceHelper.getOrderStatus(reference.currentState);
    },
    getColor(item) {
      let color = '';
      if (item.currentState === 6) color = '#00FF7F20';
      else if (this.isOrderTaken(item) === false && item.currentState === 4) color = '#FFFF0020';
      else if (this.isOrderTaken(item).length > 0 && item.currentState === 4) color = '#1E90FF20';
      else if (item.currentState === 100) color = '#F1525220';
      return color;
    },
    isOrderTaken(order) {
      if (!order || !order.reservedDate) {
        return false;
      }
      if (moment(order.reservedDate).isBefore(moment().subtract(10, 'm'))) {
        return false;
      }
      if (!order.reservedUser.fio || order.reservedUser.fio.length <= 0) {
        return '';
      }
      return order.reservedUser.fio;
    }
  }
};
</script>
<style></style>
