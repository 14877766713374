<template>
  <div>
    <v-btn color="#e21a1a" depressed v-on:click="getOrder" :loading="this.isOrderLoading">
      <v-icon color="white" left>mdi-arrow-right-bottom</v-icon>
      <span class="ReferenceOperatorWorkButtonText">ПРОСМОТРЕТЬ</span>
    </v-btn>
    <v-dialog v-model="dialog" max-width="1280" persistent>
      <v-card v-if="this.isShowWindow" class="file-upload-card" outlined>
        <v-card-title class="pb-4">{{ this.getReferenceName }}</v-card-title>
        <v-container class="OperatorWorkContainer">
          <v-row no-gutters class="RowField" v-for="item in getFieldList(orderById)" :key="item.name">
            <v-col>
              {{ item.name }}
              <v-text-field class="custom-label-color" outlined :label="item.in" :value="item.out" readonly></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="mb-2 d-flex">
          <v-spacer></v-spacer>
          <!-- <v-btn :href="`https://spravka.dzvr.ru/orders/${order.orderId}`" target="_blank" depressed color="#e21a1a" class="white--text mr-2 text-RussianRail"
            >Посмотреть в сервисе</v-btn
          > -->
          <v-btn type="submit" depressed class="mr-3 text-RussianRail" @click="closeDialog">закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import moment from 'moment';
export default {
  props: {
    order: {
      required: true
    },
    queryParams: {
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      isShowWindow: false,
      cancelReference: false,
      cancelEmail: '',
      cancelReason: '',
      isOrderLoading: false,
      rules: {
        notEmpty: [(v) => !!v || 0 || 'Обязательное поле']
      }
    };
  },
  computed: {
    getReferenceName() {
      let returnName = 'Справка';
      if (this.order.referenceType === 1) {
        returnName = 'Справка о стоимости проезда';
      } else if (this.order.referenceType === 2) {
        returnName = 'Справка о подтверждении ранее приобретенного (утерянного) проездного документа';
      } else if (this.order.referenceType === 3) {
        returnName = 'Справка о наличии мест в поезде дальнего следования';
      } else if (this.order.referenceType === 4) {
        returnName = 'Справка о проезде в поездах дальнего следования по кратчайшему маршруту следования (на момент обращения)';
      } else if (this.order.referenceType === 5) {
        returnName = 'Справка об изменении графика движения поездов';
      }
      return returnName;
    },
    ...mapGetters('reference', ['orderById'])
  },
  methods: {
    getSeatName: ReferenceHelper.getSeatName,
    getCarName: ReferenceHelper.getCarName,
    getTariffName: ReferenceHelper.getTariffName,
    getStationName: ReferenceHelper.getStationName,
    getTrainName: ReferenceHelper.getTrainName,
    async getOrder() {
      try {
        this.isOrderLoading = true;
        await this.fetchOrder(this.order.id);
        this.isShowWindow = true;
        this.dialog = true;
      } catch {
        this.isShowWindow = false;
        this.dialog = false;
      } finally {
        this.isOrderLoading = false;
      }
    },
    getFieldName(key) {
      if (key === 'createdAt') {
        return 'Создано';
      }
      if (key === 'updatedAt') {
        return 'Обновлено';
      }
      if (key === 'orderNumber') {
        return 'Номер заказа';
      }
      if (key === 'reservedDate') {
        return 'Дата бронирования заявки';
      }
      if (key === 'cancelDate') {
        return 'Дата отмены заявки';
      }
      if (key === 'cancelMessage') {
        return 'Причина отмены заявки';
      }
      if (key === 'userName') {
        return 'ФИО оператора';
      }
      if (key === 'created') {
        return 'Дата создания заявки';
      }
      if (key === 'updatedAt' || key === 'modifyPhone') {
        return 'Телефон';
      }
      if (key === 'mail' || key === 'modifyMail') {
        return 'Почта';
      }
      if (key === 'phone' || key === 'modifyPhone') {
        return 'Почта';
      }
      if (key === 'comment' || key === 'modifyComment') {
        return 'Комментарий';
      }
      if (key === 'fullName' || key === 'modifyFullName') {
        return 'ФИО клиента';
      }
      return key;
    },
    getFieldValue(key, value) {
      if (['createdAt', 'updatedAt', 'created', 'reservedDate', 'cancelDate'].includes(key)) {
        return moment(value).format('DD/MM/YY HH:mm:ss');
      }
      if (value === undefined || value === null) {
        return '';
      }
      return value.toString();
    },
    getFieldList(object) {
      const list = {};
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (
          [
            'id',
            'orderId',
            'referenceType',
            'pdfBase64',
            'sigBase64',
            'isReadyForSend',
            'currentState',
            'sendStatus',
            'sendTryCount',
            'isOrderMailSend',
            'createdAt',
            'updatedAt',
            'userId'
          ].includes(key)
        ) {
          continue;
        }
        const isModify = key.startsWith('modify');
        const id = keys[i].toLowerCase().replace('modify', '');
        if (object[key] === undefined || object[key] === null || object[key].length <= 0) {
          continue;
        }

        if (list[id] === undefined) {
          list[id] = { name: '', in: '', out: '' };
        }
        list[id].name = this.getFieldName(key);
        if (isModify === false) {
          list[id].in = this.getFieldValue(key, object[key]);
        } else {
          list[id].out = this.getFieldValue(key, object[key]);
        }
      }
      console.log('list: ', list);
      return list;
    },
    changeDateForm(pickerDate) {
      if (pickerDate === '' || pickerDate === undefined) return '';
      return pickerDate.split('-').reverse().join('.');
    },
    closeDialog() {
      this.dialog = false;
    },
    updateReferenceList() {
      this.fetchOrdersForDates({
        queryParams: this.queryParams
      });
    },
    ...mapActions('reference', ['fetchOrdersForDates']),
    ...mapActions('reference', ['fetchOrder'])
  }
};
</script>
<style>
.RowField {
  padding-left: 15px;
}
.custom-label-color .v-label .theme--light.v-label {
  color: rgb(22, 216, 241);
  opacity: 1;
}
.OperatorWorkContainer > .RowField {
  /* flex: 1 1 33%; grow | shrink | basis */
  /* height: 100px; */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 30%;
}
.OperatorWorkContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.ButtonsGroup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}
.CancelButton {
  margin-right: auto;
}

.file-upload-card {
  font-family: RussianRail;
}

.OperatorWorkField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
}

.ReferenceOperatorWorkMain {
  font-weight: 400;
}

.ReferenceOperatorWorkButtonIcon {
  margin-right: 10px;
}

.ReferenceOperatorWorkButtonText {
  color: white;
}

.ReferenceOperatorWorkButtonCancelText {
  color: gray;
}

.ReferenceOperatorWorkFieldName {
  display: flex;
  justify-content: center;
  font-size: large;
  margin-bottom: 10px;
  padding: 0;
  color: black;
}

.ReferenceOperatorWorkBlockSpacer {
  margin-top: 20px;
}

.ReferenceOperatorWorkAlertBlock {
  margin-top: 20px;
  color: #c00000;
}

.ReferenceOperatorWorkPanelHeaderWarning {
  font-weight: bold;
  color: #ed7d31;
}

.ReferenceOperatorWorkPanelHeaderError {
  font-weight: bold;
  color: #c00000;
}

.ReferenceOperatorWorkHintText {
  color: #606060;
}

.ReferenceOperatorWorkPicker {
  border-radius: 5px;
  color: white;
  font-weight: 400;
  box-shadow: 0 2px 2px #8eaadb;
  border: solid 1px #2f5496;
  margin: 20px 0 10px 0;
  padding: 2px;
}

.ReferenceOperatorWorkButtonWrapper {
  border: solid 1px #e21a1a;
  border-radius: 6px;
  width: min-content;
}

.ReferenceOperatorWorkButtonCancelWrapper {
  border: solid 1px gray;
  border-radius: 6px;
  margin-left: 20px;
}

.ReferenceOperatorWorkFileText {
  color: black;
}

.ReferenceOperatorWorkCheckResult {
  display: flex;
  //justify-content: space-between;
  margin: 20px 0 0 0;
}

.ReferenceOperatorWorkProcessList {
  display: flex;
  margin: 0 40px 0 0;
}

.ReferenceOperatorWorkProcessListNumber {
  font-size: 42px;
  color: #2f5496;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListText {
  font-size: 14px;
  color: #8eaadb;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberTotal {
  font-size: 42px;
  color: black;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextTotal {
  font-size: 14px;
  color: #a6a6a6;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberWarning {
  font-size: 42px;
  color: #ed7d31;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextWarning {
  font-size: 14px;
  color: #f4b083;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberError {
  font-size: 42px;
  color: #c00000;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextError {
  font-size: 14px;
  color: #ff4b4b;
  margin-left: 16px;
}

.ReferenceOperatorWorkTableRowWarningFirst {
  background-color: #fbe4d5;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowWarningSecond {
  background-color: white;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowErrorFirst {
  background-color: #ffc5c5;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowErrorSecond {
  background-color: white;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableHeaderWarning {
  color: black;
  //font-size: small;
  font-weight: bold;
  //background-color: #8ab0d2;
  padding: 0 6px 0 6px;
  //border-radius: 5px;
  border-bottom: solid 1px #ed7d31;
}

.ReferenceOperatorWorkTableHeaderError {
  color: black;
  //font-size: small;
  font-weight: bold;
  //background-color: #8ab0d2;
  padding: 0 6px 0 6px;
  //border-radius: 5px;
  border-bottom: solid 1px #c00000;
}

.VCardTitle {
  font-size: 0.8em !important;
  margin: 0;
  padding: 0;
}

v-col {
  padding: 0;
}
</style>
